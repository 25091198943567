.lead {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: url(/bg0.jpg);
    background-size: cover;
    overflow: hidden;
}

.lead-content {
    margin: auto;
    position: relative;
    z-index: 10;
    text-align: center;
}
.lead-content h1,
.lead-content h2 {
    margin: 0;
}
.lead-content h1 {
    color: #fff;
    font-weight: 900;
    font-size: 5em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 0.9em;
}
.lead-content h2 {
    color: #7e92c4;
    font-weight: 500;
    font-size: 2.25em;
    margin-bottom: 15px;
}
.lead-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(31, 36, 47, 0.82);
    z-index: 1;
}

@media only screen and (max-width: 992px) {
    .lead-content h1 {
        font-size: 2.4em;
    }

    .lead-content h2 {
        font-size: 1.25em;
    }

}

@media only screen and (max-width: 768px) {
    .lead-content h1 {
        font-size: 2em;
    }

    .lead-content h2 {
        font-size: 1.25em;
    }

    .lead-content a {
        padding: 10px 20px;
    }
}