.social {
    text-align: right;
}
.social ul {
    margin: 5px 0 0 0;
    padding: 0;
}
.social li {
    display: inline-block;
    font-size: 1.25em;
    list-style: none;
}
.social-button {
    display: block;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
}
.social-button:hover {
    color: #3d5286;
}

.social {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 10px;
    z-index: 2;
}

.social-box {
    font-size: 3em;
}

.social-item {
    display: inline-block;
}

@media only screen and (max-width: 992px) {
    .social-box {
        font-size: 2em;
    }
}

@media only screen and (max-width: 768px) {
    .social-box {
        font-size: 2em;
    }
}
